import { createRouter, createWebHistory } from 'vue-router'

import { useAuthStore } from '@/stores/auth'
import { routes as routesData } from '@/data/routes'
import { useProfilesStore } from '@/stores/profiles'

const routes = routesData.map((route) => ({
  path: route.path,
  name: route.name,
  component: route.component,
  children: route.items.map((item) => ({
    path: `${route.path}${item.path}`,
    name: item.name,
    meta: item.meta,
    props: item.props,
    component: item.component,
  })),
}))

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...routes,
    {
      path: '/',
      redirect: { name: 'home' },
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'notFound' },
    },
  ],
  scrollBehavior() {
    return { left: 0, top: 0 }
  },
})

router.beforeEach(async (to, _, next) => {
  const authStore = useAuthStore()
  const { canPermission } = useProfilesStore()

  const loggedIn = authStore.isLoggedIn

  if (!to.meta?.error) {
    if (!to.meta?.private && loggedIn) {
      next({ name: 'home' })
    } else if (to.meta?.private && !loggedIn) {
      next({ name: 'login' })
    } else if (loggedIn) {
      if (!canPermission(to.name as string)) {
        next({ name: 'forbidden' })
      }
    }
  }

  next()
})

export default router
