<template>
  <AppSubMenu :model="model" />
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  import { useAuthStore } from '@/stores/auth'
  import { useProfilesStore } from '@/stores/profiles'
  import { routes as routesData } from '@/data/routes'

  import AppSubMenu from './AppSubMenu.vue'

  const authStore = useAuthStore()
  const { canPermission } = useProfilesStore()

  const model = computed(() => {
    const userProfiles = authStore?.user?.profiles

    if (!userProfiles) {
      return []
    }

    return routesData
      .filter((route) => route.meta?.showMenu)
      .map(({ label, items, path: routePath }) => ({
        label,
        items: items
          .filter((routeChild) => routeChild.meta?.showMenu)
          .map(({ label, name, path, icon }) => ({
            label,
            to: `${routePath}${path}`,
            icon,
            visible: canPermission(name),
          })),
      }))
  })
</script>
