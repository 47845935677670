<template>
  <nav class="layout-breadcrumb mb-0">
    <ol>
      <template
        v-for="(breadcrumbRoute, i) in breadcrumbRoutes"
        :key="breadcrumbRoute"
      >
        <li>{{ breadcrumbRoute }}</li>
        <li
          v-if="i !== breadcrumbRoutes.length - 1"
          class="layout-breadcrumb-chevron"
        >
          <i class="pi pi-angle-right flex"></i>
        </li>
      </template>
    </ol>
  </nav>
</template>

<script setup lang="ts">
  import { useRoute } from 'vue-router'
  import { ref, watch } from 'vue'

  const route = useRoute()
  const breadcrumbRoutes = ref<string[]>([])

  const setBreadcrumbRoutes = () => {
    if (route.meta.breadcrumb) {
      breadcrumbRoutes.value = route.meta.breadcrumb as string[]

      return
    }

    breadcrumbRoutes.value = route.fullPath
      .split('?')[0]
      .split('/')
      .filter((item) => item !== '')
      .filter((item) => isNaN(Number(item)))
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1)) as string[]
  }

  watch(
    route,
    () => {
      setBreadcrumbRoutes()
    },
    { immediate: true },
  )
</script>
