<template>
  <div
    class="layout-sidebar"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="sidebar-header">
      <router-link :to="{ name: 'home' }" class="app-logo">
        <img src="/diario_direito_logo.png" height="40" alt="" />
      </router-link>
      <button
        class="layout-sidebar-anchor p-link z-2 mb-2"
        type="button"
        @click="anchor()"
      ></button>
    </div>
    <div class="layout-menu-container">
      <AppMenu />
    </div>
  </div>
</template>

<script setup lang="ts">
  import AppMenu from './AppMenu.vue'
  import { useLayout } from '@/layout/composables/layout'

  const { layoutState } = useLayout()

  let timeout: number | null = null

  const onMouseEnter = () => {
    if (!layoutState.anchored.value) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      layoutState.sidebarActive.value = true
    }
  }

  const onMouseLeave = () => {
    if (!layoutState.anchored.value) {
      if (!timeout) {
        timeout = setTimeout(
          () => (layoutState.sidebarActive.value = false),
          300,
        )
      }
    }
  }

  const anchor = () => {
    layoutState.anchored.value = !layoutState.anchored.value
  }
</script>
