import { defineStore } from 'pinia'
import Cookies from 'js-cookie'
import * as jose from 'jose'
import type { AxiosResponse } from 'axios'

import router from '@/router'
import authService from '@/services/auth.service'
import {
  COOKIE_ACCESS_TOKEN,
  COOKIE_REFRESH_TOKEN,
  COOKIE_USER_NAME,
} from '@/config/cookies'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    access_token: Cookies.get(COOKIE_ACCESS_TOKEN),
    refresh_token: Cookies.get(COOKIE_REFRESH_TOKEN),
    _user: Cookies.get(COOKIE_USER_NAME)
      ? JSON.parse(Cookies.get(COOKIE_USER_NAME) || '{}')
      : (undefined as User | undefined),
  }),
  getters: {
    isLoggedIn: (state) => !!state?.access_token,
    user: (state) => {
      if (state._user) {
        return state._user
      }

      if (!state?.access_token) {
        return undefined
      }

      const decoded = jose.decodeJwt(state?.access_token)

      return {
        id: decoded['user_id'],
        ...(decoded['user'] as {}),
      } as User
    },
  },
  actions: {
    setToken({ access_token, refresh_token }: Token) {
      this.access_token = access_token
      this.refresh_token = refresh_token

      Cookies.set(COOKIE_ACCESS_TOKEN, access_token)
      Cookies.set(COOKIE_REFRESH_TOKEN, refresh_token)
    },
    setUser(user: User) {
      this._user = user
      Cookies.set(COOKIE_USER_NAME, JSON.stringify(user))
    },
    shouldRefreshToken(response: AxiosResponse) {
      if (!(response?.status === 403)) {
        return false
      }

      const currentRefreshToken = this.refresh_token
      if (!currentRefreshToken) {
        return false
      }

      const hasAuthenticationError = response?.data?.code === 'token_not_valid'
      if (!hasAuthenticationError) {
        return false
      }

      return true
    },
    async refreshTokens() {
      const currentRefreshToken = this.refresh_token
      if (!currentRefreshToken) {
        return null
      }

      try {
        const response = await authService.refreshToken({
          refresh: this.refresh_token as string,
        })

        this.setToken({
          access_token: response.access as string,
          refresh_token: this.refresh_token as string,
        })

        return true
      } catch (error) {
        this.clear()
        return null
      }
    },
    clear() {
      this.access_token = undefined
      this.refresh_token = undefined
      this._user = undefined

      Cookies.remove(COOKIE_ACCESS_TOKEN)
      Cookies.remove(COOKIE_REFRESH_TOKEN)
      Cookies.remove(COOKIE_USER_NAME)
    },
    logout() {
      this.clear()
      router.push({ name: 'login' })
    },
  },
})
