import ApiService from './api.service'

class ContactService extends ApiService<Contact> {
  constructor() {
    super({ basePath: '/contacts' })
  }

  async download() {
    const response = await this.apiInstance.get(`/contacts/download/`, {
      responseType: 'blob',
    })
    return response?.data
  }
}

export default new ContactService()
