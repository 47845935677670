import AppLayout from '@/layout/AppLayout.vue'
import AuthBaseLayout from '@/layout/Auth/AuthBaseLayout.vue'

export const routes = [
  {
    label: 'Painel',
    path: '/painel',
    name: 'dashboard',
    icon: '',
    meta: {
      showMenu: true,
    },
    component: AppLayout,
    items: [
      {
        label: 'Home',
        icon: 'pi pi-fw pi-home',
        path: '/',
        name: 'home',
        props: false,
        meta: {
          showMenu: true,
          private: true,
        },
        component: () => import('@/views/pages/App/HomeDashboard.vue'),
      },
      {
        label: 'Perfil',
        path: '/perfil',
        icon: 'pi pi-fw pi-user',
        name: 'profile',
        props: false,
        meta: {
          breadcrumb: ['Configurações'],
          showMenu: false,
          private: true,
        },
        component: () => import('@/views/pages/App/Profile.vue'),
      },
      {
        label: 'Agenda',
        icon: 'pi pi-fw pi-calendar',
        path: '/agendas',
        name: 'agendas',
        props: false,
        meta: {
          breadcrumb: ['Painel'],
          showMenu: true,
          private: true,
        },
        component: () => import('@/views/pages/App/Schedules/index.vue'),
      },
      // {
      //   label: 'Atendimentos',
      //   icon: 'pi pi-fw pi-phone',
      //   path: '/atendimentos',
      //   name: 'services',
      //   props: false,
      //   meta: {
      //     showMenu: true,
      //     private: true,
      //   },
      //   component: () => import('@/views/pages/App/Services.vue'),
      // },
      // {
      //   label: 'Processos',
      //   icon: 'pi pi-fw pi-file',
      //   path: '/processos',
      //   name: 'proccess',
      //   props: false,
      //   meta: {
      //     showMenu: true,
      //     private: true,
      //   },
      //   component: () => import('@/views/pages/App/LawSuit.vue'),
      // },
      // {
      //   label: 'Documentos',
      //   icon: 'pi pi-fw pi-copy',
      //   path: '/documentos',
      //   name: 'documents',
      //   props: false,
      //   meta: {
      //     showMenu: true,
      //     private: true,
      //   },
      //   component: () => import('@/views/pages/App/Documents.vue'),
      // },
      // {
      //   label: 'Tarefas',
      //   icon: 'pi pi-fw pi-check-square',
      //   path: '/tarefas',
      //   name: 'tasks',
      //   props: false,
      //   meta: {
      //     showMenu: true,
      //     private: true,
      //   },
      //   component: () => import('@/views/pages/App/Tasks.vue'),
      // },
      {
        label: 'Financeiro',
        icon: 'pi pi-fw pi-wallet',
        path: '/financeiro',
        name: 'financial',
        props: false,
        meta: {
          showMenu: true,
          private: true,
        },
        component: () => import('@/views/pages/App/Financial/index.vue'),
      },
    ],
  },
  {
    label: 'Gestão',
    icon: '',
    path: '/gestao',
    name: 'management',
    meta: {
      showMenu: true,
    },
    component: AppLayout,
    items: [
      {
        label: 'Escritório',
        icon: 'pi pi-fw pi-building',
        path: '/escritorio',
        name: 'office',
        props: false,
        meta: {
          showMenu: true,
          private: true,
          breadcrumb: ['Escritório'],
        },
        component: () => import('@/views/pages/App/Office/index.vue'),
      },
      // {
      //   label: 'Relatórios',
      //   icon: 'pi pi-fw pi-file-import',
      //   path: '/relatorios',
      //   name: 'reports',
      //   props: false,
      //   meta: {
      //     showMenu: true,
      //     private: true,
      //   },
      //   component: () => import('@/views/pages/App/Reports.vue'),
      // },
      // {
      //   label: 'Indicadores',
      //   icon: 'pi pi-fw pi-chart-line',
      //   path: '/indicadores',
      //   name: 'indicators',
      //   props: false,
      //   meta: {
      //     showMenu: true,
      //     private: true,
      //   },
      //   component: () => import('@/views/pages/App/Indicators.vue'),
      // },
      {
        label: 'Contatos',
        icon: 'pi pi-fw pi-user-plus',
        path: '/contatos',
        name: 'contacts',
        props: false,
        meta: {
          showMenu: true,
          private: true,
          breadcrumb: ['Contatos'],
        },
        component: () => import('@/views/pages/App/Contacts/index.vue'),
      },
      {
        label: 'Grupos',
        icon: 'pi pi-fw pi-users',
        path: '/grupos',
        name: 'groups',
        props: false,
        meta: {
          showMenu: false,
          private: true,
          breadcrumb: ['Contatos', 'Grupos'],
        },
        component: () => import('@/views/pages/App/Contacts/Groups/index.vue'),
      },
      {
        label: 'Tags',
        icon: 'pi pi-fw pi-users',
        path: '/tags',
        name: 'tags',
        props: false,
        meta: {
          showMenu: false,
          private: true,
          breadcrumb: ['Contatos', 'Tags'],
        },
        component: () => import('@/views/pages/App/Contacts/Tags/index.vue'),
      },
      // {
      //   label: 'Configurações',
      //   icon: 'pi pi-fw pi-cog',
      //   path: '/configuracoes',
      //   name: 'configs',
      //   props: false,
      //   meta: {
      //     showMenu: false,
      //     private: true,
      //   },
      //   component: () => import('@/views/pages/App/Configs.vue'),
      // },
      // {
      //   label: 'Usuários',
      //   icon: '',
      //   path: '/usuarios',
      //   name: 'users',
      //   props: false,
      //   meta: {
      //     showMenu: false,
      //     private: true,
      //   },
      //   component: () => import('@/views/pages/App/Management.vue'),
      // },
    ],
  },
  {
    label: '',
    icon: '',
    path: '/suporte',
    name: 'support',
    meta: {
      showMenu: true,
      breadcrumb: ['Suporte'],
    },
    component: AppLayout,
    items: [
      {
        label: 'Suporte',
        icon: 'pi pi-fw pi-question-circle',
        path: '/',
        name: 'support',
        props: false,
        meta: {
          showMenu: true,
          private: true,
        },
        component: () => import('@/views/pages/App/Suports.vue'),
      },
    ],
  },
  {
    label: 'Acesso',
    icon: '',
    path: '/acesso',
    name: 'auth',
    meta: {
      showMenu: false,
    },
    component: AuthBaseLayout,
    items: [
      {
        label: 'Entrar',
        icon: '',
        path: '/entrar/:uid?/:token?',
        name: 'login',
        props: true,
        meta: {
          showMenu: false,
          private: false,
        },
        component: () => import('@/views/pages/Auth/Login.vue'),
      },
      {
        label: 'Recuperar Senha',
        icon: '',
        path: '/recuperar-senha',
        name: 'forgotPassword',
        props: false,
        meta: {
          showMenu: false,
          private: false,
        },
        component: () => import('@/views/pages/Auth/ForgotPassword.vue'),
      },
      {
        label: 'Nova Senha',
        icon: '',
        path: '/nova-senha/:uid/:token',
        name: 'resetPassword',
        props: true,
        meta: {
          showMenu: false,
          private: false,
        },
        component: () => import('@/views/pages/Auth/NewPassword.vue'),
      },
      {
        label: 'Convidado',
        icon: '',
        path: '/convidado/:uid/:token',
        name: 'guest',
        props: true,
        meta: {
          showMenu: false,
          private: false,
        },
        component: () => import('@/views/pages/Auth/NewPassword.vue'),
      },
      {
        label: 'Nova Conta',
        icon: '',
        path: '/nova-conta',
        name: 'register',
        props: false,
        meta: {
          showMenu: false,
          private: false,
        },
        component: () => import('@/views/pages/Auth/Register.vue'),
      },
    ],
  },
  {
    label: 'Erro',
    icon: '',
    path: '/erro',
    name: 'error',
    meta: {
      showMenu: false,
    },
    component: null,
    items: [
      {
        label: 'Acesso Negado',
        icon: '',
        path: '/acesso-negado',
        name: 'forbidden',
        props: false,
        meta: {
          showMenu: false,
          private: false,
          error: true,
        },
        component: () => import('@/views/pages/Errors/Forbidden.vue'),
      },
      {
        label: 'Erro inesperado',
        icon: '',
        path: '/inesperado',
        name: 'unexpected',
        props: false,
        meta: {
          showMenu: false,
          private: false,
          error: true,
        },
        component: () => import('@/views/pages/Errors/Error.vue'),
      },
      {
        label: 'Página não encontrada',
        icon: '',
        path: '/pagina-nao-encontrada',
        name: 'notFound',
        props: false,
        meta: {
          showMenu: false,
          private: false,
          error: true,
        },
        component: () => import('@/views/pages/Errors/NotFound.vue'),
      },
    ],
  },
]
