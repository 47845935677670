import type { AxiosInstance } from 'axios'

import api from './axios.service'

export default class ApiService<T> implements IApiService<T> {
  private basePath: string
  protected apiInstance: AxiosInstance

  constructor({ basePath }: IApiServiceOptions) {
    this.basePath = basePath
    this.apiInstance = api

    this.create = this.create.bind(this)
    this.getAll = this.getAll.bind(this)
    this.getById = this.getById.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
  }

  async getAll({
    offset = 10,
    limit = 10,
    sort = 'name',
    search,
    filters,
  }: TQueryParamsAll): Promise<TPaginatedResponse<T>> {
    const response = await this.apiInstance.get<TPaginatedResponse<T>>(
      `${this.basePath}/`,
      {
        params: {
          limit,
          offset,
          ordering: sort,
          search,
          ...(Object.keys(filters || {}).length > 0 ? { ...filters } : {}),
        },
      },
    )
    return response?.data
  }

  async getById(id: string): Promise<Partial<T>> {
    if (!id) {
      return {} as Partial<T>
    }

    const response = await this.apiInstance.get<T>(`${this.basePath}/${id}/`)
    return response?.data
  }

  async create(infos: Partial<T>): Promise<T> {
    const response = await this.apiInstance.post<T>(`${this.basePath}/`, infos)
    return response?.data
  }

  async update({ id, infos }: IUpdateOptions<T>): Promise<T> {
    const response = await this.apiInstance.patch<T>(
      `${this.basePath}/${id}/`,
      infos,
    )
    return response?.data
  }

  async delete(id: string): Promise<void> {
    await this.apiInstance.delete(`${this.basePath}/${id}/`)
  }
}
