<script setup lang="ts">
  import { ref, onMounted, onBeforeUnmount } from 'vue'
  import type { Ref } from 'vue'

  import AppBreadcrumb from './AppBreadcrumb.vue'
  import { useLayout } from '@/layout/composables/layout'
  import { useAuthStore } from '@/stores'
  import { getInitials } from '@/utils/formatters'

  const { onMenuToggle, onProfileSidebarToggle, onConfigSidebarToggle } =
    useLayout()
  const { user } = useAuthStore()

  const outsideClickListener: Ref<((_event: any) => void) | null> = ref(null)
  const topbarMenuActive = ref(false)

  onMounted(() => {
    bindOutsideClickListener()
  })

  onBeforeUnmount(() => {
    unbindOutsideClickListener()
  })

  const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
      outsideClickListener.value = (event) => {
        if (isOutsideClicked(event)) {
          topbarMenuActive.value = false
        }
      }
      document.addEventListener('click', outsideClickListener.value)
    }
  }
  const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
      document.removeEventListener('click', outsideClickListener.value)
      outsideClickListener.value = null
    }
  }
  const isOutsideClicked = (event: any) => {
    if (!topbarMenuActive.value) return

    const sidebarEl = document.querySelector('.layout-topbar-menu')
    const topbarEl = document.querySelector('.layout-topbar-menu-button')

    return !(
      sidebarEl?.isSameNode(event.target) ||
      sidebarEl?.contains(event.target) ||
      topbarEl?.isSameNode(event.target) ||
      topbarEl?.contains(event.target)
    )
  }

  const showProfileSidebar = () => {
    onProfileSidebarToggle()
  }
  const onConfigButtonClick = () => {
    onConfigSidebarToggle()
  }
</script>

<template>
  <div class="layout-topbar mb-0">
    <div class="topbar-start">
      <Button
        type="button"
        class="topbar-menubutton p-link p-trigger"
        @click="onMenuToggle"
      >
        <i class="pi pi-bars"></i>
      </Button>

      <AppBreadcrumb class="topbar-breadcrumb"></AppBreadcrumb>
    </div>

    <div class="topbar-end">
      <ul class="topbar-menu">
        <li class="topbar-search">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <InputText
              type="text"
              placeholder="Pesquisar"
              class="w-12rem sm:w-full"
            />
          </span>
        </li>
        <li class="ml-3" @click="onConfigButtonClick">
          <Button icon="pi pi-cog" text rounded severity="secondary"></Button>
        </li>
        <li class="ml-2" @click="onConfigButtonClick">
          <Button icon="pi pi-bell" text rounded severity="secondary"></Button>
        </li>
        <li class="topbar-profile">
          <Avatar
            :label="getInitials(user?.name)"
            icon="pi pi-user"
            class="p-link"
            @click="showProfileSidebar"
            size="large"
            shape="circle"
            style="
              background-color: var(--surface-overlay);
              color: var(--primary-color);
              border: 1px solid #d1d5db;
            "
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
