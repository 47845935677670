import api from './axios.service'

export default {
  async login(user: AuthLoginRequest) {
    const response = await api.post<AuthLoginJWTResponse>(
      '/auth/jwt/create/',
      user,
      {
        headers: {
          Authorization: '',
        },
        skipAuthHeader: true,
      },
    )
    return response?.data
  },
  async refreshToken(infos: AuthRefreshTokenRequest) {
    const response = await api.post<Partial<AuthLoginJWTResponse>>(
      '/auth/jwt/refresh/',
      infos,
      {
        headers: {
          Authorization: '',
        },
        skipAuthHeader: true,
      },
    )
    return response?.data
  },
  async logout() {
    await api.post(
      '/auth/token/logout',
      {},
      {
        headers: {
          Authorization: '',
        },
        skipAuthHeader: true,
      },
    )
  },
  async register(user: AuthRegisterRequest) {
    const response = await api.post('/auth/users/', user, {
      headers: {
        Authorization: '',
      },
      skipAuthHeader: true,
    })
    return response.status
  },
  async activateAccount(infos: AuthActivateAccountRequest) {
    await api.post('/auth/users/activation/', infos, {
      headers: {
        Authorization: '',
      },
      skipAuthHeader: true,
    })
  },
  async forgotPassword(infos: AuthForgotPasswordRequest) {
    await api.post('/auth/users/reset_password/', infos, {
      headers: {
        Authorization: '',
      },
      skipAuthHeader: true,
    })
  },
  async resetPassword(infos: AuthResetPasswordRequest) {
    await api.post('/auth/users/reset_password_confirm/', infos, {
      headers: {
        Authorization: '',
      },
      skipAuthHeader: true,
    })
  },
}
