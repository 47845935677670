import { defineStore } from 'pinia'

import { useAuthStore } from '@/stores/auth'
import { EProfile } from '@/types/profiles.types'

export const useProfilesStore = defineStore({
  id: 'profiles',
  state: () => ({
    rules: {
      admin: [
        'home',
        'agendas',
        'services',
        'proccess',
        'documents',
        'tasks',
        'financial',
        'office',
        'reports',
        'indicators',
        'contacts',
        'support',
        'groups',
        'tags',
        'profile',
      ],
      lawyer: [
        'home',
        'agendas',
        'services',
        'proccess',
        'documents',
        'tasks',
        'reports',
        'indicators',
        'contacts',
        'groups',
        'tags',
        'support',
        'profile',
      ],
      attendant: [
        'home',
        'agendas',
        'services',
        'tasks',
        'contacts',
        'groups',
        'tags',
        'support',
        'profile',
      ],
      financial: [
        'home',
        'financial',
        'reports',
        'indicators',
        'support',
        'profile',
      ],
    },
  }),
  getters: {
    currentProfile: (): Profile | undefined => {
      const authStore = useAuthStore()

      if (!authStore.isLoggedIn) {
        return undefined
      }

      const userProfiles = authStore?.user?.profiles

      if (!userProfiles) {
        return undefined
      }

      return userProfiles[0]
    },
  },
  actions: {
    canPermission(page: string) {
      if (this.currentProfile?.rule) {
        return Object.values(EProfile).some((profile) => {
          if (profile === this.currentProfile?.rule) {
            return this.rules[profile].includes(page)
          }

          return false
        })
      }

      return false
    },
  },
})
