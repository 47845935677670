import { defineStore } from 'pinia'
import { useToast } from 'primevue/usetoast'

type Notify = {
  summary?: string
  detail: any
}

export const useNotifyStore = defineStore({
  id: 'notify',
  state: () => ({
    toast: useToast(),
    closable: true,
    life: 3000,
  }),
  actions: {
    setCloseable(value: boolean) {
      this.closable = value

      return this
    },
    setLife(value: number) {
      this.life = value

      return this
    },
    success({ summary = 'Sucesso!', detail }: Notify) {
      this.toast.add({
        severity: 'success',
        summary,
        detail,
        life: this.life,
        closable: this.closable,
      })

      return this
    },
    info({ summary = 'Info!', detail }: Notify) {
      this.toast.add({
        severity: 'info',
        summary,
        detail,
        life: this.life,
        closable: this.closable,
      })

      return this
    },
    warn({ summary = 'Atenção!', detail }: Notify) {
      this.toast.add({
        severity: 'warn',
        summary,
        detail,
        life: this.life,
        closable: this.closable,
      })

      return this
    },
    error({ summary = 'Erro!', detail }: Notify) {
      this.toast.add({
        severity: 'error',
        summary,
        detail,
        life: this.life,
        closable: this.closable,
      })

      return this
    },
  },
})
