<template>
  <Sidebar
    v-model:visible="layoutState.profileSidebarVisible.value"
    position="right"
    class="layout-profile-sidebar w-full sm:w-25rem"
  >
    <div class="flex flex-column mx-auto md:mx-0">
      <span class="mb-2 font-semibold">Bem-vindo</span>
      <span class="text-color-secondary font-medium mb-5"> {{ userName }}</span>

      <ul class="list-none m-0 p-0">
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
            @click.prevent="profile"
          >
            <span>
              <i class="pi pi-user text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">Perfil</span>
              <p class="text-color-secondary m-0">
                Gerencie os dados do seu perfil
              </p>
            </div>
          </a>
        </li>
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
            @click.prevent="logout"
          >
            <span>
              <i class="pi pi-power-off text-xl text-primary"></i>
            </span>
            <div class="ml-3">
              <a class="mb-2 font-semibold">Sair</a>
              <p class="text-color-secondary m-0">Sair do sistema</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </Sidebar>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  import router from '@/router'
  import { useLayout } from '@/layout/composables/layout'
  import { useAuthStore } from '@/stores/auth'

  const { layoutState, onProfileSidebarToggle } = useLayout()

  const authStore = useAuthStore()

  const userName = computed(() => authStore.user?.name)

  const profile = () => {
    onProfileSidebarToggle()
    router.push({ name: 'profile' })
  }

  const logout = () => {
    onProfileSidebarToggle()
    authStore.logout()
  }
</script>
