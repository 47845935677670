import { useQuery } from '@tanstack/vue-query'
import { defineStore } from 'pinia'
import type { DataTablePageEvent } from 'primevue/datatable'
import { useRoute } from 'vue-router'

import { CACHE_TIME } from '@/config'

export const createGenericStore = <T>(
  storeId: string,
  service: IApiService<T>,
  initialLimit = 10,
  orderField = 'name',
) => {
  return defineStore(storeId, {
    state: () => ({
      order: {
        field: orderField,
        sort: 'asc' as 'asc' | 'desc',
      },
      page: 1,
      limit: initialLimit,
      search: '',
      isFetching: false,
      filters: {} as Record<string, any>,
      query: {} as any,
      debouncedRefetch: null as any,
    }),

    getters: {
      results(state) {
        return state.query?.data?.results || []
      },
      count(state) {
        return state.query?.data?.count || 0
      },
      isLoading(state) {
        return state.query?.isLoading
      },
      isEmpty(state) {
        return (
          !state.isFetching &&
          state.query?.data?.count === 0 &&
          state.page === 1 &&
          Object.keys(state.filters).length === 0 &&
          state.search === ''
        )
      },
      sort(state) {
        return `${state.order.sort === 'asc' ? '' : '-'}${state.order.field}`
      },
      offset(state) {
        return (state.page - 1) * state.limit
      },
    },

    actions: {
      init() {
        const { query: queryString } = useRoute()

        this.page = parseInt(queryString.page as string) || this.page
        this.limit = parseInt(queryString.limit as string) || this.limit
        this.order.field = (queryString.sortField as string) || this.order.field
        this.order.sort =
          (queryString.sortOrder as 'asc' | 'desc') || this.order.sort
        this.search = (queryString.search as string) || this.search

        const filters: Record<string, string | string[]> = {}
        Object.keys(queryString).forEach((key) => {
          const match = key.match(/filters\[(\d+)\]\[(\w+)\]/)
          if (match) {
            const [, , prop] = match
            if (prop === 'key') {
              const filterKey = queryString[key] as string
              const filterValue = queryString[
                `filters[${match[1]}][value]`
              ] as string
              filters[filterKey] = filterValue
            }
          }
        })

        this.filters = filters

        this.query = useQuery<TPaginatedResponse<T>>({
          queryKey: [
            storeId,
            this.offset,
            this.limit,
            this.order,
            this.search,
            JSON.stringify(this.filters),
          ],
          queryFn: () =>
            service.getAll({
              offset: this.offset,
              limit: this.limit,
              sort: this.sort,
              search: this.search,
              filters: this.filters,
            }),
          refetchOnWindowFocus: false,
          staleTime: CACHE_TIME,
        })
      },
      updateRouteParams() {
        const params = new URLSearchParams()

        params.set('page', String(this.page))
        params.set('limit', String(this.limit))
        params.set('sortField', this.order.field)
        params.set('sortOrder', this.order.sort)
        params.set('search', this.search)

        Object.keys(this.filters).forEach((key, index) => {
          const filterValue = this.filters[key]

          params.set(`filters[${index}][key]`, key)
          params.set(`filters[${index}][value]`, filterValue)
        })

        this.router.replace({
          query: Object.fromEntries(params.entries()),
        })
      },
      setPage({ first, rows }: DataTablePageEvent) {
        this.page = first / rows + 1
        this.limit = rows
        this.isFetching = true

        this.query.refetch()
        this.updateRouteParams()
      },
      setFilterSearch({ search = '', filters = {} }: Partial<TQueryParamsAll>) {
        this.search = search
        this.filters = Object.fromEntries(
          Object.entries(filters).filter(([_, value]) =>
            Array.isArray(value) ? value.length : !!value,
          ),
        )
        this.page = 1
        this.isFetching = true

        this.query.refetch()
        this.updateRouteParams()
      },
      setFieldOrder(field: string) {
        this.order.field = field

        this.updateRouteParams()
      },
      setSortOrder(sort = 0) {
        this.order.sort = sort > 0 ? 'asc' : 'desc'

        this.updateRouteParams()
      },
      async refetch() {
        this.isFetching = true
        await this.query.refetch()
        this.isFetching = false
      },
    },
  })
}
